* {
  margin: 0;
  padding: 0;
}
/* Variables */

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;


  /* @@@@@@@@ Testimonial @@@@@@@ */

  --card-clr: #161922;
	--body-clr: #191d28;
	--primary-clr: #f0bf6a;
	--heading-clr: #dadada;
	--text-clr: #767a86;
}
body {
  background-color: #F95454;
}

/* @@@@@@@@@@@Borrowed@@@@@@@ */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
}


h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */


/* @@@@@@@@@@Borrowe@@@@@@@@@ */
html {
  scroll-behavior: smooth;
}
.navbarMain {
  height: 100px;
  /* width: 100%; */
  /* background: greenyellow; */
  display: flex;
  /* padding: 2% 6%; */
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}
.nav-links {
  flex: 1;
  text-align: right;
  box-sizing: border-box;
}
.navLogo {
  width: 120px;
  height: 80px;
  /* background: #f0bf6a; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navLogo span {
  font-size: 11px;
  color: whitesmoke;
}
.nav-links ul li {
  list-style: none;
  display: inline-block;
  padding: 8px 12px;

}

.nav-links ul li::after {
  content: "";
  width: 0%;
  height: 2px;
  background: #161922;
  display: block;
  margin: auto;
  transition: .5s;
}
.nav-links ul li:hover::after {
  width: 100%;
}

.nav-links ul li a {
  color: whitesmoke;
  text-decoration: none;
  font-size: 16px;
}
/* $$$$$$$$$$$$$ MOBILE #######*/


.FaDiv {
  display: none;
}
.close-nav {
  visibility: hidden;
  color: whitesmoke;
  font-size: 25px;
  margin-top: 10px;
  cursor: pointer;
}
.close-nav:hover {
  color: #F95454;
}
@media(max-width: 700px) {
  .navLogo {
    margin-left: -30px;
  }
  .welc-h2 {
    font-size: 18px;
  }
  .nav-links ul li {
    display: block;
  }
  .nav-links ul li:hover {
    background: #F95454;
    /* color: #F95454; */
  }
  .nav-links {
    display: none;
    position: absolute;
    background: #161922;
    height: 100vh;
    width: 200px;
    top: 0;
    right: 0;
    text-align: left;
    z-index: 2;
  }
  .nav-show {
    display: block;
  }
  .nav-links ul li {
    padding-left: 30px;
    /* font-size: 20px; */
  }
  .nav-links ul li a {
    
    font-size: 20px;
  }
  .close-nav {
    visibility: visible;
  }


  .FaDiv {
    display: block;
  }
  
}
/* ********* NAVBar ************** */
.Tlogo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

/* @@@@@@@@@@@@@@@@ MAINPage @@@@@@@@ */

.welcome-TC {
  height: 100vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  
}
.welcome-TC h2 {
  color: whitesmoke;
  margin-bottom: 30px;
}
.welcome-TC h4 {
  color: #191d28;
}
.cours-TC {
  width: 500px;
  height: 100%;
  /* background: #767a86; */
  margin: auto;
  text-align: left;
  padding: 10px;
}
.cours-TC p {
  color: whitesmoke;
}
.cours-TC span {
  padding-left: 40px;
}

.link-register {
  padding: 5px 20px;
  border: 2px solid whitesmoke;
  /* font-size: 22px; */
  font-weight: bold;
  color: whitesmoke;
  /* padding-bottom: 13px; */
  margin-top: 20px;
  text-decoration: none;
  transition: .3s;
}
.link-register:hover {
  background: #161922;
  border: 2px solid #161922;

}
@media(max-width: 700px) {
  .cours-TC {
    width: 250px;
    /* background: greenyellow; */
  }

  .FaDiv span {
    font-size: 20px;
    color: whitesmoke;
    cursor: pointer;
  }
  .link-register {
    padding: 5px 20px;
    border: 2px solid whitesmoke;
    font-size: 15px;
    color: whitesmoke;
    padding-bottom: 10px;
  }
}
/* @@@@@@@@@@@@@@@@@  LOGIN  @@@@@@@@@@@@@@ */
.login-main {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-div {
  width: 400px;
  height: 350px;
  background: whitesmoke;
  padding-left: 20px;
}
.signin-div {
  width: 400px;
  height: 400px;
  background: whitesmoke;
  padding-left: 20px;
}

.inp-divs {
  margin-bottom: 15px;
}
.all-inputs {
  padding: 8px 12px;
  width: 300px;
  outline: none;
  
}
.login-btn {
  padding: 8px 30px;
  border: none;
  background: #F95454;
  color: whitesmoke;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  transition: .3s;
}
.login-btn:hover {
  background: #161922;
}
.or {
  margin-top: 20px;
}
.create-account {
  color: #F95454;
  font-weight: bold;
}
.create-account:hover {
  color: #161922;
}
@media(max-width: 700px) {
  .login-div {
    width: 85%;
    height: 350px;
    background: whitesmoke;
    padding-left: 20px;
  } 
  .signin-div {
    width: 85%;
    height: 400px;
    background: whitesmoke;
    padding-left: 20px;
  }

  .all-inputs {
    width: 250px;

    
  }
}

/************ Get Started Page ********************* */
.prereq {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.prereq h2 {
  color: whitesmoke;

}
.prereq-und {
  width: 100px;
  height: 2px;
  background: #161922;
  margin-top: 10px;
  margin-bottom: 10px;
}
.prereq-p {
  color: whitesmoke;
}
.the-mods {
  display: flex;

}
.mod-div {
  width: 250px;
  height: 100%;
  background: whitesmoke;
  padding: 20px;
  cursor: pointer;

}
.mod-div:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.push-rt {
  margin-right: 20px;
}
.prereq-btn {
  padding: 16px 20px;
  background: #161922;
  color: whitesmoke;
  border: none;
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}
.prereq-btn:hover {
  background: whitesmoke;
  color: #161922;
}
.get-started {
  width: 100%;
  height: 70px;
  /* background: yellowgreen; */
  display: flex;
  justify-content: center;
}
.makeup {
  padding: 10px 20px;
  background: #161922;
  color: whitesmoke;
  border: none;
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
  margin-left: 10px;
}

.makeup a {
  color: whitesmoke;
  
}
.makeup a:hover {
  color: #F95454;

}

@media(max-width: 670px) {
  .the-mods {
    flex-direction: column;
  
  }
  .push-rt {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .prereq-btn {
    padding: 13px 10px;
    
    font-size: 10px;
    font-weight: bold;
    margin-top: 20px;
    
  }


  .makeup {
    padding: 10px 10px;
    
    border: none;
    font-size: 10px;
    font-weight: bold;
    margin-top: 20px;
    
    margin-left: 10px;
    height: 18px;
  }
  
  .makeup a {
    font-size: 10px;
  }
}
@media(max-width: 500px) {
  .get-started {
    flex-direction: column;
    height: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  .prereq-btn {
    margin-bottom: 20px;
    width: 60%;
  }
  .makeup {
    width: 60%;
    margin: auto;
  }
}

/************ Get Started Page ********************* */


.trial-yy {
  display: none;
}
.trial-active {
  display: block;
}

/* ############## VIDEO ##################### */
.tr-div {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: auto;
}
.tr-div h2 {
  text-align: center;
  color: whitesmoke;
  margin-bottom: 20px;
}
.vidz-underscore {
  width: 130px;
  height: 2px;
  background: #161922;
  margin: auto;
  
}
.tr-aside {
  width: 100%;
  height: 100%;
  margin: auto;
}
.tr-div p {
  text-align: center;
  color: #161922;
  margin-top: 20px;
}


.tr-vidz h5 {
  color: whitesmoke;
}
.the-vidz {
  /* min-height: calc(100vh - 100px); */
  min-height: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(96px, 1fr));
  gap: 8px;
  
}

.boda {
  height: 1px;
  width: 100%;
  background: #161922;
  margin-top: 20px;
  margin-bottom: 20px;
}



@media(max-width: 600px) {
  .tr-div {
    width: 90%;
  }
  .the-vidz video {
    width: 350px;
  }

}

.mpesa-sec {
  width: 80%;
  height: 100%;
  text-align: center;
  margin: auto;
  padding-top: 30px;
}
.mpesa-sec h4 {
  margin-bottom: 30px;
}
.pay-btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: whitesmoke;
  background-color: #161922;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  
}
.pay-btn:hover {
  background: #F95454;
}
.py-btn-div {
  height: 50px;
  width: 100%;
  /* background: #f0bf6a; */
  text-align: center;
  margin-top: 20px;
}
.py-buttons {
  padding: 10px 22px;
  background: #161922;
  color: whitesmoke;
  border: none;
  font-weight: bold;
  transition: .3s;
  cursor: pointer;
}
.py-buttons:hover {
  background: whitesmoke;
  color: #161922;
}

.paid-yes {
  padding: 10px 22px;
  background: #161922;
  color: whitesmoke;
  border: none;
  font-weight: bold;
  transition: .3s;
  cursor: pointer;
  /* visibility: hidden; */
  display: none;
}

/* #########  FEEDBack  ############### */

.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}

.sidebar-wrapper.show {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: #F95454;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.close-btn:hover {
  color: #161922;
}
.fa-vid {
  color: #F95454;
  font-size: 20px;
}

@media(max-width: 600px) {
  .sidebar {
    width: 70%;
  }
  
}
